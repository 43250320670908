.site-main{
	//DISTRO OVERRIDE FOR ACCESSIBILITY

	.marker, .form-required{
		color: $wcm-dark-orange;
	}

	.footer-contact__map-link a:hover{
	    background-color: $wcm-dark-orange;
	}

	.footer-contact__site-name{
		a:hover, a:focus {
	    	color: $wcm-white;
		}
	}

	/*Sidebar*/
	.information-sidebar{
		.site-affiliation{
		    font-weight: 700;
		}	
	}

	/*Map*/
	.google-maps{
		iframe{
			width: 100%;
			height: 450px;
			border: 0px;
		}
	}

	/*Buttons*/
	.dndcinfo{
	    color: #fff;
		background-color: $wcm-white !important;
		&:hover{
			background-color: $wcm-white !important;
			background-color: #b31b1b !important;
		}
	}

	.pagination>li>a:hover, .pagination>li>a:focus, .pagination>li>span:hover, .pagination>li>span:focus{
		color: $wcm-red;
	} 	
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
	max-width: none;
	height: 50px;
	border: 1px solid $wcm-border-gray;
	box-shadow: none;
	appearance: none;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	  background-repeat: no-repeat;
	  background-position: right 15px top 50%;
	background-size: 20px 20px;
	padding-right: 50px;
 }
