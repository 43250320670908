/*SIDEBAR*/
.information-column{
	@mixin cta_sidebar{
	    font-family: "open sans-regular",sans-serif;
	    text-align: center;
	    color: $wcm-med-gray;
	    margin: 5px 0px;		
	}

	h4.cta_sidebar_phone{
		@include cta_sidebar();
		font-size: 13px;
		letter-spacing: 2px;
	}

	h2.cta_sidebar_phone{
	    @include cta_sidebar();
	    font-size: 25px;
	}

	.wcm-cta{
		margin-top: 20px;
	}
}

/*PATIENT CARE*/
.callout-link{
	margin: 0 0 12.5px;
	a{
		letter-spacing: 2px;
		font-weight: bold;
		color: $wcm-red;
		&:hover{
			text-decoration: none;
			color: $wcm-red;
		}
	   	&:after{
	  		content:'\003e';
	  		padding-left: 5px;
		}
	}
}

.grey_box_full{
	background-color: $wcm-bg-gray;
	padding: 100px 5% 0% 5%;
	margin: 10px 5px 20px 5px;
	display: inline-block;
	width: 100%;
    border: solid 5px $wcm-white;
	@media (max-width : 991px){
		padding: 65px 5% 0% 5%;
		border: none;
	}
	p{
		margin: 0px;
	}
}
.grey_box_half{
	background-color: $wcm-bg-gray;
	padding: 100px 10% 0% 10%;
	margin: 10px 5px;
	display: inline-block;
	width: 100%;
    border: solid 5px $wcm-white;
	@media (max-width : 991px){
		padding: 65px 5% 0% 5%;
		border: none;
	}
	a:hover{
		text-decoration: none;
		color: $wcm-bright-orange;
	}
   	a::after{
  		content:'\003e';
  		padding-left: 5px;
	}
}
.grey_box_quarter{
	float: left;
	width: 50%;
	padding: 0px 10px;
	@media (max-width : 991px){
		width: 100%;
		float: left;
		padding: 0px;
	}
}
.patient-care-content{
	.patient-care-quote{
	    text-align: center;
	    font-size: 20px;
	    font-weight: 500;
	    font-style: italic;
	    padding: 5% 0% 2% 0%;
	}
	.col-md-6, .col-md-12{
		padding: 0px;
	}
	.hover-image-full{
	    text-align: center;
	    padding: 50px 55px 0px 55px;
	    margin-bottom: -100px;
	    position: relative;
	    @media (max-width : 991px){
	    	padding: 20px 25px 0px 25px;
	    	margin-bottom: -65px;
	    }
	    img{
	    	width: 99%;
	    	@media (max-width : 991px){
	    		width: 95%;
	    	}
	    }
	}
	.hover-image-half{
	    text-align: center;
	    padding: 50px 55px 0px 55px;
	    margin-bottom: -100px;
	    position: relative;
	    @media (max-width : 991px){
	    	padding: 20px 25px 0px 25px;
	    	margin-bottom: -65px;
	    }
	    img{
	    	width: 100%;
	    	@media (max-width : 991px){
	    		width: 95%;
	    	}
	    }
	}
	.btn--wcm{
		width: 270px;
	}
	hr{
		width: 50%;
	}
}

/*CONDITION & TREATMENT BUTTON*/
#related-content-sidebar{
	.btn--wcm{
		white-space: normal !important;

		a{
			&:hover, &:focus {
		    	color: $wcm-white;
		    	text-decoration: none;
			}
		}
	}
	.btn--wcm:hover, .btn--wcm:focus{
		color: $wcm-white !important;
		text-decoration: none;

		a{
			color: $wcm-white !important;
			text-decoration: none;
		}
	}
}

.view-conditions, .view-treatments{
    padding: 0px 0px 20px 0px;
    margin: 0px 0px 20px 0px;
}

/*PATIENT REVIEW*/
.circle-color1 {
    background-color: #bd2b23;
}
.circle-color2 {
    background-color: #ffca3e;
}
.circle-color3 {
    background-color: #d75229;
}
.review-circle {
    display: table;
    float: left;
    border-radius: 50%;
    width: 75px;
    height: 75px;
}

.view-patient-reviews{
	.col-md-2{
		padding: 20px 10px;
		@media (max-width: 991px){
			float: left !important;
		}
	}
	.cta-icon {
	    width: 30px;
	    height: 30px;
	    margin-left: 23px;
	    margin-top: 23px;
	    color: $wcm-white;
	    fill: $wcm-white;
	}
	.col-md-10{
		padding: 15px 0px;
		@media (max-width: 991px){
			float: none !important;
			margin-left: 95px;
		}
		border-bottom: solid 1px $wcm-border-gray;
	}
	.views-row-last{
		.col-md-10{
			border-bottom: none;
		}
	}
}

/*CLINICAL TRIAL*/
.dndcinfo {
    color: $wcm-white;
    background-color: $wcm-dark-orange;
    text-decoration: none;
    padding: 5px 10px;
    line-height: 35px;
    border-radius: 5px;
    font-size: 13px;
    &:hover {
	    text-decoration: none;
	    color: $wcm-white;
	    background-color: $wcm-bright-orange;
	}
}
.view-clinical-trial{
	//Button
	margin-top: 30px;
	
	.views-row{
		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 20px;
		margin-bottom: 15px;
	}
	.views-row-last{
		border: none;
	}

	.views-field {
		padding-bottom: 2px;
		.views-label {
			font-weight: 600;
		}
		.field-content {
			display: inline;
		}
	}

	.clinical_trials_title{
	    padding-bottom: 10px;
		font-size: 17px;
	    // font-weight: bold;
	    // line-height: 26px;
	    // margin: 20px 0px;
	    // a::after{
		// 	content: none;
		// }
	}
	.study_details_button{
		font-size: 13px;
		padding: 20px 0px;
		a::after{
			content: none;
		}
	}

	.views-field-nothing {
		padding-top: 10px;
	}

	a.pc-btn-small {
		display: inline-block;
    	background-color: $wcm-dark-orange;
	    border-radius: 7px;
	    height: 30px;
	    line-height: 30px;
	    padding: 0 14px;
	    color: #fff;
	    margin-right: 10px;
	    font-size: 13px;
	}
}

/*PROFILES*/
.view-profiles{
	.view-content{
		display: inline-block;
	}
	.profile-row{
		float: left;
		width: 100%;
		padding: 20px 0px;
		.profile-list-image img{
		    float: left;
		    padding: 0px 10px 0px 0px;
		}
		.views-field-field-professional-title{
			padding: 20px 0px;
		}
		.views-field-field-pops-profile-link{
		    padding-bottom: 30px;
		}
		.views-field-field-biographical-info{
	    	float: left;
    		width: 70%;
		}
	}
}

/*WEBFORM*/
//Request an Appointment
.node-type-webform{
	h3.pane-title{
	    padding-bottom: 25px;
	}

	hr{
	    margin-top: 20px;
		border-top: 5px solid #eee;
	}

	.website-icon{
	    float: left;
    	padding: 0px 25px 25px 0px;
	}

}

/*PUBLICAITONS*/

#biblio-node{
	a{
      color: $wcm-red;
      &:hover{
        color: $wcm-red;        
      }
    }
}


#biblio-header {
    display: block;
    height: 50px;

    ul {
	    float: left;
	    width: 100%;
	    height: 20px;
	    list-style: none;
	    padding-left: 0px;

	    li {
		    float: left;
		    padding-right: 20px;

		    a {
			    color: $wcm-med-gray;
			}
		}
	}

	.biblio-export {
	    text-align: right;
	    text-decoration: none;
	    float: right;
	}

	.biblio-alpha-line {
	    text-align: center;
	}
}

.biblio-separator-bar {
    color: $wcm-black;
    font-weight: bold;
    background-color: #e1e1e1;
    border: 1px solid #ccc;
    padding: 0.5em;
    margin: 1em 0 1em 0;
}

.biblio-entry {
    margin: 1em 0 1em 0;

    .biblio-title {
	    font-weight: bold;
	    text-decoration: none;
	    font-style: normal;
	    line-height: normal;
	    text-align: left;
	    font-family: "Open Sans",sans-serif;
	    color: $wcm-dark-orange;
	}

	ul.biblio-export-buttons, ul.biblio-export-buttons li {
	    background: transparent;
	    list-style-image: none;
	    list-style-type: none;
	    display: inline;
	    border-bottom: 0px;
	    border-right: 1px;
	    padding: 0;
	    margin: 0.1em;
	    li {
		    background: transparent;
		    list-style-image: none;
		    list-style-type: none;
		    display: inline;
		    border-bottom: 0px;
		    border-right: 1px;
		    padding: 0;
		    margin: 0.1em;
		}
	}
}

/*NEWS AND UPDATES*/
//Custom News title
.news_title{
    position: absolute;
    top: -250px;
    left: 34%;
    right: 0;
    bottom: 0;
    z-index: 1;

    h1{
    	display: inline-block;
	    vertical-align: middle;
	    color: white;
	    margin: .5em 0 0 0;
    }
}
.view-news{
	.view-filters{
		.views-exposed-form{
			.views-exposed-widget {
		    	display: inline-block;
		    	width: 100%;
			}

			padding-bottom: 25px;
			.select2-container{
				width: 340px !important;
			}
		}
	}
	
	.view-news-teaser{
		padding: 0px 0px 40px 0px;
		margin: 40px 0px 0px 0px;
	    border-bottom: solid 1px $wcm-border-gray;
		.teaser-image{
			img{
				float: right;
				margin: 0px 10px;
			}
		}
		h3.teaser-title{
			margin: 0px;
		}
		a.read-more{
			padding: 20px 0px 0px 0px;
    		display: inline-block;
		}
		a.read-more:after{
			    content: '\e80d';
			    color: $wcm-bright-orange;
			    padding: 5px 0 0 10px;
			    font-size: 60%;
			    vertical-align: top;
			    font-family: "fontello";
			    font-style: normal;
			    font-weight: normal;
			    speak: none;
			    display: inline-block;
			    text-decoration: inherit;
			    text-align: center;
			    font-variant: normal;
			    text-transform: none;
			    line-height: 1em;
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			    text-decoration: none;
			    line-height: 1.5;
		    }
	}
	.views-row-last{
		border-bottom: none;
	}
}

/*NEWS INDIVIDUAL PAGE*/
.news-featured-image{
	.hero-image img {
    	width: auto;
	}
}

/*CALENDAR MOBILE VIEW*/
/*NAVIGATION*/
#cal-nav-container{

	h3.cal-title{
		text-align: center;
		padding-bottom: 10px;
		a{
			padding: 0px 50px;
			text-decoration: none;
			@media all and (max-width: 768px){
				padding: 0px 5px;
			}
		}
		a:after{
				content: none;
		}
	}
}

.calendar-calendar {
	.month-view .full td.single-day div.monthview{
		background: $wcm-yellow;
		padding: 5px;
	}
	td.empty {
    	color: $wcm-med-gray;
	}
	.month-view .full td.date-box.today {
 	   	border-width: 2px 2px 0px 2px;
		border-style: solid;
    	border-color: $wcm-dark-orange;
    	@media all and (max-width: 768px){
    		border: none;
    	}
	}
	.month-view .full tr td.single-day.today {
   		border-bottom: 2px solid $wcm-dark-orange;
   		@media all and (max-width: 768px){
   			border: none;
   		}
	}
	.month-view{
		.full {
			tr td.today{
	    		background: none;
    			border-left: 2px solid $wcm-dark-orange;
    			border-right: 2px solid $wcm-dark-orange;
			}
			td.multi-day {
				div.monthview{
					color: $wcm-dark-orange;
					background: $wcm-yellow;
				}
				.inner .monthview .cutoff{
					background: $wcm-yellow;	
				}
			}
		}
	}
	td .inner div.day a {
    	color: $wcm-dark-orange;
	}
}
@media all and (max-width: 768px) {
/* BEGIN Calendar responsive CSS - tablet portrait */
  /* Force table to not be like tables anymore */
  .calendar-calendar table,
  .calendar-calendar tbody,
  .calendar-calendar th,
  .calendar-calendar td,
  .calendar-calendar tr  { 
	display: block; 
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .calendar-calendar thead,
  .calendar-calendar thead tr,
  .calendar-calendar tr.date-box { 
	position: absolute;
	top: -9999px;
	left: -9999px;
  }
  .calendar-calendar tr td.no-entry  { 
	border-bottom: 1px solid #ccc; 
  }
  .calendar-calendar td:before{   /* Format & Label the day headings */
	/* Now like a table header */
	width: 100%;  
	white-space: nowrap;
	clear: both;
	text-align: center;
	display: block;
	box-sizing: border-box;
	color: $wcm-white;
	background-color: #888;
	padding: 1px;
	content: attr(data-label); 
	height: auto;
  }
  .calendar-calendar td.today,
  .calendar-calendar .full tr td.single-day.today{
    border-top: 2px solid #0074ab;
	-webkit-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
  }
  .calendar-calendar .full td, .calendar-calendar .month-view .full td, .calendar-calendar .week-view .full td, .calendar-calendar .day-view td{
    padding: 0;
  }
  .calendar-calendar td,
  .calendar-calendar td.calendar-agenda-items  { 
	/* Behave  like a "row" */
	border: none;
	position: relative;
	width: 100%;
  }
  /*.calendar-calendar td.past{
    display: none;
  }*/
  .calendar-calendar td.no-entry,
  .calendar-calendar td.empty{
    display: none;
  }
  .calendar-calendar td.calendar-agenda-items div.calendar{
    padding: 0;
  }
  .calendar-calendar div.calendar,
  .calendar-calendar .date-display-single,
  .calendar-calendar td span.date-display-single  {
    font-size: 14px;
	font-weight: normal;
	margin: 0 !important;
  }
  .calendar-calendar .inner .item{
    padding: 10px 10px 0;
  }
  .calendar-calendar .inner .item:last-child{
	padding-bottom: 10px;
  }
  .calendar-calendar .full tr.single-day .no-entry{
    height: 44px !important;
	line-height: 24px;
  }
  .calendar-calendar .full tr.single-day .no-entry .inner,
  .calendar-calendar .no-entry .inner{
    height: auto !important;
	line-height: 1px;
  }
  .calendar-calendar .inner .views-field-title-field{
    display: inline-block;
  }
  /* END Calendar responsive CSS - tablet portrait */
}