.front{
  p{
    font-size: 16px;
    letter-spacing: .01em;
    a.callout-links{
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 2px;
      padding: 10px 0px;
      &:after{
        content: '\E802';
      }
    }
  }

  .grey_bg{
    background-color: none;
    @include breakpoint ($lg){
      background-color: $wcm-bg-gray;
    }
  }

  /*HOMEPAGE HERO SECTION*/
  .cta_container{
    text-align: center;
    background-color: $wcm-white;
    position: relative;
    @include breakpoint ($lg){
      height: 175px;
    }
    @include breakpoint ($md){
      height: 175px;
    }

    .cta{
      position: relative;
      background-color: $wcm-bg-gray;
      margin-left: 20px;
      margin-right: 20px;

      .cta-container{
        float: left;
        width: 33.33%;
        height: 115px;
        background: #B31B1A;
        @media all and (max-width: 1024px){
          float: none;
          width: 100%;
          margin: 0px;
          border: none;
          height: auto;
        }

        .cta_icons{
          width: 100px;
          border-radius: 50px;
          border: solid 10px $wcm-white;
          background: $wcm-white;
          overflow: hidden;
          @include breakpoint ($xs){
            display: none;
          }

          @include breakpoint ($sm){
            display: none;
          }

          @include breakpoint ($md){
            display: inline-block;
          }

          @include breakpoint ($lg){
            display: inline-block;
          }

          &:hover {
            transition: all 500ms ease;
            margin-top: -10px;
            margin-bottom: 10px;
            transform: rotate(0deg);
          }
          
          img{
            background: $wcm-white;
            border: solid 10px $wcm-white;
            border-radius: 0px;
          }

        }
        @mixin cta-inner{
          height: 80px;
          color: $wcm-white;
          font-size: 20px;
          letter-spacing: 2px;
          line-height: 1.3;
          font-family: "Open Sans";
          font-weight: bold;
          padding-top: 30px;
          margin: 0px;
          text-align: center;
          transition: all .2s ease-in-out;
          
          @media all and (max-width: 1024px){
            float: none;
            width: 100%;
            height: auto;
            margin: 0px;
            padding: 10px 0px 20px 0px;
            border-bottom: solid 1px $wcm-white;
          }
        }
        h2.cta-left, h2.cta-center, h2.cta-right{
          @include cta-inner();
        }
      }


      .cta-container-center{
        @include breakpoint ($md){
          border-right: solid 1px $wcm-white;
          border-left: solid 1px $wcm-white;
        }
        @include breakpoint ($lg){
          border-right: solid 1px $wcm-white;
          border-left: solid 1px $wcm-white;
        }
      
      }

      a{
        &:hover, &:active, &:focus{
          color: #B31B1A;
        }

        &:after{
          content: none !important;
        }
      }
    }
  }

  /*ABOUT US*/
  .about_us_container{
    position: relative;
    background-color: $wcm-white;
    .homepage_about_left{
      .about_us_image{
        margin: 50px 0% 20% 0%;
        box-shadow: 50px 50px 0px $wcm-bg-gray;
        transition: all .2s ease-in-out;
        width: 85%;
        img{
          width: 100%;
          margin-bottom: 0px;
        }
        @media all and (max-width: 991px){
          margin: 5% 0%;
          width: 100%;
          box-shadow: none;
          text-align: center;
          img{
            width: 100%;
            margin-bottom: 0px;
          }
        }
      }
    }
    .homepage_about_right{
      padding: 5% 8%;
      h2{
        font-size: 40px;
        font-weight: bold;
        color: $wcm-dark-gray;
        position: relative;
        margin: 30px 0px;
      }
      h2:after {
        content: "";
        background: $wcm-dark-gray;
        position: absolute;
        bottom: 0px;
        left: 0;
        height: 1px;
        width: 100px;
        margin: 6px 0px -15px -35px;
      }
      @media all and (max-width: 991px){
        padding: 3%;
        h2:after {
          content: "";
          background: $wcm-black;
          position: relative;
          top: 75px;
          left: 0;
          height: 1px;
          width: 100px;
          margin: -20px 0px -15px 0px;
          float: left;
        }
      }
    }
  }

  /*PATIENT CARE*/
  .homepage_patient_care_container{
    background: url(/sites/default/files/styles/panopoly_image_original/public/patient_care.jpg);
    width: 100%;
    background-size: cover;
    background-position: center;
    .homepage_patient_care{
      .patient-care-content-container{
        overflow: hidden;
        height: 475px;
        width: 100%;
        transition: all 1s;
        display: block;
        padding: 0;
        margin: 0;
        @media all and (max-width: 1024px){
          height: 380px;
        }
        @media all and (max-width: 991px){
          height: auto;
        }

        @mixin patinent-care-internal{
          display: inline-block;
          position: absolute;
          padding: 0px;
          width:: 60%;
          color: $wcm-white;
          @media all and (max-width: 1024px){
          
          }
          h2{
            font-size: 40px;
            font-weight: bold;
            color: $wcm-white;
          }
          h2:after {
            content: "";
            background: $wcm-white;
            position: absolute;
            bottom: 0px;
            left: 0;
            height: 1px;
            width: 65px;
            margin: 0px 0px -10px -20px;
          }

          .full{
            float: left;
            width: 100%;
            padding-right: 20px;

            a{
              color: $wcm-white;
            }
            a:after{
              content:'\003e';
              padding-left: 5px;
            }
            a:hover{
              color: $wcm-yellow !important;
              text-decoration: none !important;
            }

          }

          .left{
            float: left;
            width: 50%;
            padding-right: 20px;
            border-right: solid 1px $wcm-white;
            a{
              color: $wcm-white;
            }
            a:after{
              content:'\003e';
              padding-left: 5px;
            }
            a:hover{
              color: $wcm-yellow !important;
              text-decoration: none !important;
            }
          }
          .right{
            float: left;
            width: 50%;
            padding-left: 30px;
            a{
              color: $wcm-white;
            }
            a:after{
              content:'\003e';
              padding-left: 5px;
              
            }
            a:hover{
              color: $wcm-yellow !important;
              text-decoration: none !important;
            }
          }
          a{
            color: $wcm-yellow;
          }
        }
        .patient-care-title{
          @include patinent-care-internal;
          top: 50px;
          h2:after {
            content: "";
            background: $wcm-white;
            position: absolute;
            bottom: 0px;
            left: 0;
            height: 1px;
            width: 100px;
            margin: 6px 0px -15px -35px;
          }
          @media all and (max-width: 1200px){
            top: 25px;
          }
          @media all and (max-width: 991px){
            width: 100%;
            h2:after {
              content: "";
              background: $wcm-white;
              position: relative;
              top: 75px;
              left: 0;
              height: 1px;
              width: 100px;
              margin: -20px 0px -15px 0px;
              float: left;
            }
            position: relative;
            left: 0%;
          }
        }
        .patient-care-content{
          @include patinent-care-internal;
          top: 145px;
          @media all and (min-width: 1440px){
            width: 50%;
            top: 33%;
          }
          @media all and (max-width: 1200px){
            top: 33%;
          }
          @media all and (max-width: 991px){
            top: 0px;
            left: 0px;
            position: relative;
            color: $wcm-white;
            width: 100%;
            padding: 10% 0%;
            h3{
              color: $wcm-dark-gray;
            }
            .left, .right{
              width: 100%;
              border: none;
              padding: 0px;
              a{
                color: $wcm-dark-orange;
              }
            }
         }
         .callout-link{
          padding-top: 15px;
         }
        }
        img{
          width: 100%;
          top: 20;
        }
      }
    }
  }

  /*PATIENT REVIEW*/
  .homepage_patient_review_container{
    background-color: $wcm-bg-gray;
    a.read-more{
      color: $wcm-red;
      &:hover{
        color: $wcm-red;        
      }
    }
    .view-patient-reviews{
      text-align: center;
      padding: 3% 0%;
      @media all and (max-width: 1024px){
        padding: 3%
      }
      .patient_review_homepage_icon{
        display: inline-block;
        border: 5px solid $wcm-bright-orange;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        padding: 15px;
        margin: 1% 0% 2% 0%;
      }
    }

    @mixin patient-review-internal{
      padding-top: 20px;
    }
    .views-field-field-patient-review-name{
      @include patient-review-internal;
    }
    .view-footer{
      @include patient-review-internal;
      a{
        font-weight: bold;
      }
      a:hover{
        text-decoration: none;
      }
      a::after{
        content:'\003e';
        padding-left: 5px;
      }
    }
  }

  /*NEWS AND EVENTS*/
  /*NEWS*/

  .news-container{
    @include breakpoint ($sm){
      padding-left: 0px;
      padding-right: 0px;
    }

    @include breakpoint ($xs){
      padding-left: 0px;
      padding-right: 0px; 
    }
    .row{
      @include breakpoint ($lg){
        display: inherit !important;
      }

      @include breakpoint ($md){
        display: inherit !important;
      }

      @include breakpoint ($sm){
        display: flex;
        flex-direction: column;
      }

      @include breakpoint ($xs){
        display: flex;
        flex-direction: column;
      }
      
      .homepage_news{
        @include breakpoint ($lg){
          //height: 580px;
          padding-top: 15px;
        }

        @include breakpoint ($md){
          //height: 580px;
          padding-top: 15px;
        }

        @include breakpoint ($sm){
          order: 2;
          padding-top: 25px;
          padding-bottom: 25px;
        }

        @include breakpoint ($xs){
          order: 2;
          padding-top: 25px;
          padding-bottom: 25px;
        }

        .view-news{
          h4.teaser-text{
            a{
              &:hover{
                color: $wcm-dark-orange !important;
              }
            }
          }
          .views-row{
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: solid 1px #ddd;
          
            h4.teaser-text{
              margin: 0px;
              min-height: 45px;
              a{
                color: $wcm-dark-gray;
              }
            }

            img.panopoly-image-quarter{
              margin-top: 0px;
              margin-right: 25px;
              //margin-bottom: 30px;
            }

            em.teaser-date{
              color: $wcm-dark-gray;
            }

            h3.teaser-text{
              margin: 0px;
            }

            hr{
              @include breakpoint ($xs){
                width: 100%;
              }

              @include breakpoint ($sm){
                width: 100%;
              }

              @include breakpoint ($md){
                width: auto;
              }

              @include breakpoint ($lg){
                width: auto;
              }
            }

            .views-field-field-source-link{
              a.external-link:after{
                content: '\e80d';
                padding-left: 10px;
                padding-top: 5px;
              }
            }
          }

          .views-row-last{
            border-bottom: none;
          }
        }
      }

      .homepage_news_image{
        //height: 580px;
      }
    }
  }
  

  /*EVENTS*/
  .homepage_events{
    h3.event_title{
      font-size: 25px;
    }

    .view-events{
      .views-row{
        float: left;
        width: 50%;
        padding-right: 50px;
        overflow: hidden;

        .views-field-field-event-start-date{
          float: left;
          height: 65px;
          width: 85px;
          padding: 0% 1%;
          margin: 5% 5% 30% 0%;
          clear: both;
          border-right: solid 1px $wcm-black;
          word-wrap: break-word;
          text-align: center;
          h2.event_date{
            font-size: 30px;
            font-family: $font-family-sans-serif;
            font-weight: normal;
            color: $wcm-black;
            margin: 0px;
            padding-top: 0px;
          }
          @media all and (max-width: 991px){
            height: 55px;
            margin: 5% 5% 20% 0%;
          }
        }
        @media all and (max-width: 991px){
          width: auto;
        }
        h3.event_title{
          font-weight: normal;
          font-family: $font-family-sans-serif;
          a::after{
            content: none;
          }
        }
        .views-field-event_date, .event_description, .event_link {
          padding-left: 100px;
          padding-top: 10px;
        }
        .event_link{
          a::after{
            content: none;
          }
        }
        @media all and (max-width: 991px){
          height: auto;
        }
      }
    }
  }
}